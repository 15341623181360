/*
GENERAL STYLING
*/
html {
}
body {
  background-color: #f5f5f5 !important;
  color: #4e4e4e !important;
  background: linear-gradient(
      0deg,
      rgba(117, 117, 117, 0.5),
      rgba(117, 117, 117, 0.5)
    ),
    url(/static/media/AB.0b2f70aa.jpg);
  /* -webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover; */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  /* position: fixed; */
  /* font-family: Arial, Helvetica, sans-serif; */
}

a {
  color: #1e1e1e;
  transition: all 0.5s ease-in-out;
}

a:hover {
  color: #0078ff;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.p-r {
  position: relative;
}

.color-a {
  color: #0078ff;
}

.color-d {
  color: #f5f5f5;
}

.color-text-a {
  color: #4e4e4e;
}

.box-shadow,
.paralax-mf,
.service-box,
.work-box,
.card-blog {
  box-shadow: 0 13px 8px -10px rgba(0, 0, 0, 0.1);
}

.box-shadow-a,
.button:hover {
  box-shadow: 0 0 0 4px #cde1f8;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-6 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.1;
}

.avatar {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  overflow: hidden;
}

.bg-image {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

.overlay-mf {
  background-color: rgba(255, 255, 255);
}

.overlay-mf {
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  /* opacity: 0.7; */
}

.paralax-mf {
  position: relative;
  padding: 8rem 0;
}

.display-table {
  width: 100%;
  height: 100%;
  display: table;
}

.table-cell {
  display: table-cell;
  margin-top: 100px;
  vertical-align: middle;
}

/* Sections */

.sect-4 {
  padding: 4rem 0;
}

.sect-pt4 {
  padding-top: 4rem;
}

.sect-mt4 {
  /* margin-top: 4rem; */
}

/* Title S */

.title-s {
  font-weight: 600;
  color: #1e1e1e;
  font-size: 1.1rem;
}

/* Title A */

.title-box {
  margin-bottom: 4rem;
}

.title-a {
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}

.subtitle-a {
  color: #4e4e4e;
}

.line-mf {
  width: 40px;
  height: 5px;
  background-color: #0078ff;
  margin: 0 auto;
}

/* Title Left */

.title-box-2 {
  margin-bottom: 3rem;
}

.title-left {
  font-size: 2rem;
  position: relative;
  color: black;
}

/* Box */

.box-pl2 {
  padding-left: 2rem;
}

.box-shadow-full {
  margin-top: 1.5rem;
  padding: 3rem 1.25rem;
  position: relative;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

/*
NAVBAR
*/

.navbar-b {
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-top: 1.563rem;
  padding-bottom: 1.563rem;
}

.navbar-b.navbar-reduce {
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}

.navbar-b.navbar-trans .nav-item,
.navbar-b.navbar-reduce .nav-item {
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
}

.navbar-b.navbar-trans .nav-link,
.navbar-b.navbar-reduce .nav-link {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

.navbar-b.navbar-trans .nav-link:before,
.navbar-b.navbar-reduce .nav-link:before {
  content: '';
  position: absolute;
  margin-left: 0px;
  width: 0%;
  bottom: 0;
  left: 0;
  height: 2px;
  transition: all 500ms ease;
}

.navbar-b.navbar-trans .nav-link:hover,
.navbar-b.navbar-reduce .nav-link:hover {
  color: #1b1b1b;
}

.navbar-b.navbar-trans .nav-link:hover:before,
.navbar-b.navbar-reduce .nav-link:hover:before {
  width: 35px;
}

.navbar-b.navbar-trans .show > .nav-link:before,
.navbar-b.navbar-trans .active > .nav-link:before,
.navbar-b.navbar-trans .nav-link.show:before,
.navbar-b.navbar-trans .nav-link.active:before,
.navbar-b.navbar-reduce .show > .nav-link:before,
.navbar-b.navbar-reduce .active > .nav-link:before,
.navbar-b.navbar-reduce .nav-link.show:before,
.navbar-b.navbar-reduce .nav-link.active:before {
  width: 35px;
}

.navbar-b.navbar-trans .nav-link:before {
  background-color: #fff;
}

.navbar-b.navbar-trans .nav-link:hover {
  color: #fff;
}

.navbar-b.navbar-trans .show > .nav-link,
.navbar-b.navbar-trans .active > .nav-link,
.navbar-b.navbar-trans .nav-link.show,
.navbar-b.navbar-trans .nav-link.active {
  color: #fff;
}

.navbar-b.navbar-reduce {
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-b.navbar-reduce .nav-link {
  color: #0078ff;
}

.navbar-b.navbar-reduce .nav-link:before {
  background-color: #0078ff;
}

.navbar-b.navbar-reduce .nav-link:hover {
  color: #0078ff;
}

.navbar-b.navbar-reduce .show > .nav-link,
.navbar-b.navbar-reduce .active > .nav-link,
.navbar-b.navbar-reduce .nav-link.show,
.navbar-b.navbar-reduce .nav-link.active {
  color: #0078ff;
}

.navbar-b.navbar-reduce .navbar-brand {
  color: #0078ff;
}

.navbar-b.navbar-reduce .navbar-toggler span {
  background-color: #1b1b1b;
}

.navbar-b .navbar-brand {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
}

.navbar-b .navbar-nav .dropdown-item.show .dropdown-menu,
.navbar-b .dropdown.show .dropdown-menu,
.navbar-b .dropdown-btn.show .dropdown-menu {
  transform: translate3d(0px, 0px, 0px);
  visibility: visible !important;
}

.navbar-b .dropdown-menu {
  margin: 1.12rem 0 0;
  border-radius: 0;
}

.navbar-b .dropdown-menu .dropdown-item {
  padding: 0.7rem 1.7rem;
  transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item:hover {
  background-color: #0078ff;
  color: #fff;
  transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item.active {
  background-color: #0078ff;
}

/* Hamburger Navbar */

.navbar-toggler {
  position: relative;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span {
  display: block;
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  transform: rotate(0deg);
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(-135deg);
  opacity: 0.9;
}

/*
INTRO
*/

.intro {
  height: 100vh;
  position: relative;
  color: #fff;
}

.intro .intro-content {
  /* text-align: center; */
  position: relative;
}

.intro .overlay-intro {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
}

.intro .intro-title {
  color: #fff;
  font-weight: 600;
  font-size: 3rem;
}

.intro .intro-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

/* .intro .text-slider-items {
  font-weight: bolder;
} */

.intro-single {
  height: 350px;
}

.intro-single .intro-content {
  margin-top: 30px;
}

.intro-single .intro-title {
  text-transform: uppercase;
  font-size: 3rem;
}

.intro-single .breadcrumb {
  background-color: transparent;
  color: #0078ff;
}

.intro-single .breadcrumb .breadcrumb-item:before {
  color: #cde1f8;
}

.intro-single .breadcrumb .breadcrumb-item.active {
  color: #cde1f8;
}

.intro-single .breadcrumb a {
  color: #fff;
}

/*
ABOUT
*/

.about-mf .box-shadow-full {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.about-mf .about-img {
  margin-bottom: 2rem;
}

.about-mf .about-img img {
  margin-left: 10px;
}

.skill-mf span {
  color: #4e4e4e;
}

.skill-mf .progress {
  background-color: #cde1f8;
  margin: 0.5rem 0 1.2rem 0;
  border-radius: 0;
  height: 0.7rem;
}

.skill-mf .progress .progress-bar {
  height: 0.7rem;
  background-color: #0078ff;
}

/*
CONTACT
*/

.footer-paralax {
  padding: 4rem 0 0 0;
}

/*
FOOTER
*/

footer {
  text-align: center;
  color: #fff;
  padding-bottom: 4rem;
}

footer .copyright {
  margin-bottom: 0.3rem;
}

/*
CONTACT 
*/

/*
BUTTON
*/

.button {
  display: inline-block;
  padding: 0.3rem 0.6rem;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  border-radius: 0.3rem;
  border: 1px solid transparent;
  transition: all 500ms ease;
  cursor: pointer;
}

.button:focus {
  outline: 0;
}

.button:hover {
  background-color: #0062d3;
  color: #fff;
  transition: all 500ms ease;
}

/*  Custom */
.contact-mf {
  margin-top: 6rem;
}
.route {
  /* height: 100%; */
}

.timeline {
  overflow: hidden;
}

.row-content {
  margin-left: 10px;
  margin-right: 30px;
}

.flexBox {
  display: flex;

  flex-direction: row;
}

.description {
  /* display: flex;
  flex-direction: column; */
  width: 30%;
}

.board {
  background-color: rgba(97, 97, 97, 0.5);
}

.footer {
  position: fixed;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.half-white-board {
  margin-top: 1.5rem;
  padding: 3rem 1.25rem;
  position: relative;
  background-color: #fff;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
}

.default-icon-color {
  color: 'white';
}
.faq-body .home-box {
  overflow-y: scroll;
  max-height: 60vh;
}

.hidden-el {
  visibility: hidden;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 70px; */
  height: 80vh;
}

.product-row {
  width: 33.33%;
  padding: 10px;
  box-sizing: border-box;
}

.product-list img {
  max-width: 100%;
  max-height: 100%;
}

.endy {
  text-align: center;
}

